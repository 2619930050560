import React, { useEffect, useState } from 'react';
import { UilUserCircle } from '@iconscout/react-unicons'; // นำเข้าไอคอน
import axios from 'axios'; // นำเข้า axios
import './Profile.css'; // นำเข้าไฟล์ CSS

const Profile = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [username, setUsername] = useState(''); // เก็บชื่อผู้ใช้
  const [roundCounts, setRoundCounts] = useState({}); // เก็บจำนวนรอบที่ตรวจแล้วสำหรับแต่ละรอบ
  const [failCounts, setFailCounts] = useState({}); // เก็บจำนวนการตรวจผิดในแต่ละรอบ
  const [loading, setLoading] = useState(true); // ใช้เช็คสถานะการโหลดข้อมูล

  useEffect(() => {
    const fetchUserName = () => {
      const loggedInUser = localStorage.getItem('username');
      setUsername(loggedInUser || ''); // อัปเดตชื่อผู้ใช้
    };

    fetchUserName();
  }, []); // ใช้แค่ครั้งเดียวเมื่อเริ่มต้น

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const response = await axios.get('http://autobotlotto.mcpay.in:5678/api/report');
        const data = response.data;

        const counts = {};
        const fails = {};

        data.forEach(item => {
          if (item.username === username) {
            if (!counts[item.Round]) {
              counts[item.Round] = 0;
            }
            counts[item.Round] += 1;

            if (!fails[item.Round]) {
              fails[item.Round] = 0;
            }
            if (item.fail === "1") {
              fails[item.Round] += 1;
            }
          }
        });

        setRoundCounts(counts);
        setFailCounts(fails);
      } catch (error) {
        console.error("Error fetching report data:", error);
        setErrorMessage("เกิดข้อผิดพลาดในการดึงข้อมูล");
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      fetchReportData();
    }
  }, [username]); // จะเรียก fetchReportData เมื่อ username เปลี่ยนแปลง


  // คำนวณจำนวนรวมทั้งหมด
  const totalRounds = Object.values(roundCounts).reduce((sum, count) => sum + count, 0);
  const totalFails = Object.values(failCounts).reduce((sum, count) => sum + count, 0);

  return (
    <div className="profile-container">
      <div className="profile-card">
        <div className="user-info">
          <UilUserCircle size={40} color="#000000" /> {/* ไอคอนขนาดเล็ก */}
          {username ? (
            <p className="username">{username}</p> // แสดงชื่อผู้ใช้
          ) : (
            <p className="loading-message">กำลังโหลดข้อมูล...</p> // ข้อความขณะโหลด
          )}
        </div>

        {loading ? (
          <p>กำลังโหลดข้อมูล...</p> // ข้อความขณะโหลดข้อมูล
        ) : (
          <div className="check-info">
            <table>
              <thead>
                <tr>
                  <th>รอบที่</th>
                  <th>ตรวจแล้ว</th>
                  <th>ตรวจผิด</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(roundCounts).map((round) => (
                  <tr key={round}>
                    <td>{round}</td>
                    <td>{roundCounts[round]}</td>
                    <td>{failCounts[round] || 0}</td>
                  </tr>
                ))}
                <tr>
                  <td><strong>รวมทั้งหมด</strong></td>
                  <td><strong>{totalRounds}</strong></td>
                  <td><strong>{totalFails}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {errorMessage && <p className="error-message">{errorMessage}</p>}

      </div>
    </div>
  );
};

export default Profile;
