import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Roundone.css';

const Roundthree = () => {
  const navigate = useNavigate();
  const [lottoData, setLottoData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [displayUsername, setDisplayUsername] = useState(localStorage.getItem('username'));
  const [timeoutReached, setTimeoutReached] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [showBackConfirmPopup, setShowBackConfirmPopup] = useState(false);
  const productContainerRef = useRef(null);
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [confirmUnload, setConfirmUnload] = useState(false);
  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const { round } = location.state || {};
  const itemsPerPage = 1;


  const handleCloseAlert = () => {
    setShowAlert(false); // ปิดการแจ้งเตือน
  };

  const AlertModal = ({ message, onClose }) => {
    return (
      <div className="alert-modal">
        <div className="alert-content">
          <p>{message}</p>
          <button onClick={onClose} className="close-btn">ปิด</button>
        </div>
      </div>
    );
  };


  useEffect(() => {
    // ฟังก์ชันสำหรับแจ้งเตือนเมื่อรีเฟรชหรือออกจากหน้า
    const handleBeforeUnload = (e) => {
      if (!confirmUnload) {
        e.preventDefault(); // ป้องกันข้อความเตือนจากเบราว์เซอร์
        setShowRefreshModal(true); // เปิด modal ยืนยันการรีเฟรช
        return ''; // ป้องกันเบราว์เซอร์จากการแสดงการแจ้งเตือนปกติ
      }
    };


    // ฟังก์ชันสำหรับจัดการ popstate (ย้อนกลับ)
    const handlePopState = (e) => {
      // แสดง modal ยืนยัน
      setShowModal(true);
    };

    // เพิ่ม event listeners สำหรับ beforeunload และ popstate
    window.addEventListener('beforeunload', handleBeforeUnload); // ทำงานเมื่อมีการรีเฟรชหรือปิดหน้า
    window.addEventListener('popstate', handlePopState);         // ทำงานเมื่อกดย้อนกลับ

    // เปลี่ยนสถานะแรกเพื่อให้การกดย้อนกลับมีผล
    window.history.pushState(null, null, window.location.pathname);

    return () => {
      // ลบ event listeners เมื่อ component ถูกทำลาย
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [confirmUnload]);


  // ฟังก์ชันยืนยันการรีเฟรชหน้า
  const handleConfirmRefresh = async () => {
    setShowRefreshModal(false); // ปิด modal
    setConfirmUnload(true); // อนุญาตให้รีเฟรช

    try {
      const lottoIdsToUpdate = currentLottoData.map(item => item.id);
      await updateUserStatus(lottoIdsToUpdate, ''); // อัปเดตข้อมูลก่อนรีเฟรช
    } catch (error) {
      console.error("Error updating user status:", error);
    }

    window.location.reload(); // รีเฟรชหน้า
  };

  // ฟังก์ชันยกเลิกการรีเฟรช
  const handleCancelRefresh = () => {
    setShowRefreshModal(false); // ปิด modal
  };

  // ฟังก์ชันปิด Modal เมื่อผู้ใช้เลือกออก
  const handleCloseModal = async (confirm) => {
    setShowModal(false);  // ปิด Modal
    if (confirm) {
      // เรียกฟังก์ชัน updateUserStatus เมื่อผู้ใช้กดยืนยัน
      try {

        if (timeoutId) {
          clearTimeout(timeoutId);  // หยุดการทำงานของ timeout ที่กำหนดไว้
          setTimeoutId(null);        // รีเซ็ตค่า timeoutId เป็น null เพื่อไม่ให้ใช้งานต่อ
        }

        const lottoIdsToUpdate = currentLottoData.map(item => item.id);
        await updateUserStatus(lottoIdsToUpdate, ''); // ส่งค่าไปอัปเดต
        // window.history.back(); // หลังจากอัปเดตแล้ว, กลับไปหน้าก่อนหน้า
        navigate('/checklottery');
      } catch (error) {
        console.error("Error updating user status:", error); // จัดการข้อผิดพลาด
      }
    } else {
      // หากยกเลิก, ไม่ทำอะไร
      window.history.pushState(null, null, window.location.pathname); // ป้องกันการย้อนกลับ
    }
  };

  useEffect(() => {
    const checkLoginStatus = () => {
      const username = localStorage.getItem('username');
      if (username) {
        setIsLoggedIn(true);
      }
    };

    checkLoginStatus();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      fetchLottoData();
    }
  }, [isLoggedIn]);

  const fetchLottoData = async () => {
    try {
      const response = await fetch('http://autobotlotto.mcpay.in:5678/api/lotto-numbers-roundthree'); // เปลี่ยน endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Fetched Lotto Data:', data);

      // ตรวจสอบว่า ทุกแถวมีค่า userstatus หรือไม่
      const hasEmptyUserStatus = data.some(item => !item.userstatus); // ตรวจสอบว่าในข้อมูลมีแถวที่ userstatus ว่าง

      // ถ้าทุกแถวมีค่า userstatus และไม่มีค่าว่างให้แจ้งเตือน
      if (!hasEmptyUserStatus) {
        setShowAlert(true);
      }

      const filteredData = data.filter(item =>
        item.Round_two !== displayUsername &&
        !item.userstatusRthree &&
        item.Round_one !== displayUsername
      );

      if (filteredData.length === 0) {
        setShowAlert(true);
      }

      setLottoData(filteredData.map(item => ({
        ...item,
        lottoNumbers: formatLottoNumber(item.lottoNumbers),
        status: '1',
        remark: ''
      })));

      const lottoIdsToUpdate = filteredData.slice(0, 1).map(item => item.id);
      await updateUserStatus(lottoIdsToUpdate, displayUsername);

      const timer = setTimeout(() => {
        setTimeoutReached(true);
        updateUserStatus(lottoIdsToUpdate, '');
      }, 60000);

      setTimeoutId(timer);

      return () => clearTimeout(timer);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  useEffect(() => {
    if (timeoutReached) {
      setShowPopup(true);
    }
  }, [timeoutReached]);

  const formatLottoNumber = (number) => ({
    part1: number.slice(0, 2),
    part2: number.slice(2, 8),
    part3: number.slice(8, 10),
    part4: number.slice(10),
  });

  const handleInputChange = (id, partIndex, newValue) => {
    const maxLengths = [2, 6, 2, 2];
    const updatedValue = newValue.slice(0, maxLengths[partIndex]);

    setLottoData((prevData) =>
      prevData.map((item) => {
        if (item.id === id) {
          const updatedParts = {
            ...item.lottoNumbers,
            [partIndex]: updatedValue
          };
          return { ...item, lottoNumbers: updatedParts };
        }
        return item;
      })
    );
  };

  const handleStatusChange = (id, newStatus) => {
    setLottoData((prevData) =>
      prevData.map((item) => {
        if (item.id === id) {
          return { ...item, status: newStatus };
        }
        return item;
      })
    );
  };

  const handleRemarkChange = (id, newRemark) => {
    setLottoData((prevData) =>
      prevData.map((item) => {
        if (item.id === id) {
          return { ...item, remark: newRemark };
        }
        return item;
      })
    );
  };

  const handleButtonClick = async () => {
    console.log('Current Lotto Data:', currentLottoData);

    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    const lottoIdsToUpdate = currentLottoData.map(item => {
      // รวมหมายเลขล็อตโต้เป็นก้อนเดียว
      const combinedLottoNumbers = Object.values(item.lottoNumbers).join(''); // รวมเลขใน lottoNumbers เป็นสตริงเดียว

      return {
        id: item.id,
        imageFileName: item.imageFileName,
        lottoNumbers: combinedLottoNumbers, // ใช้ combinedLottoNumbers ที่รวมแล้ว
        userstatus: displayUsername, // ส่งชื่อผู้ใช้ไปที่นี่
        PassFail: item.status, // เปลี่ยนชื่อเป็น PassFail ตามที่คุณต้องการ
        Period_no: item.Period_no,
        remark: item.remark,
        round: round
      };
    });

    console.log('Data to be sent to the server:', lottoIdsToUpdate);

    try {
      const response = await fetch('http://autobotlotto.mcpay.in:5678/api/update-lotto-data-roundthree', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(lottoIdsToUpdate),
      });

      if (!response.ok) {
        throw new Error('Failed to update lotto data');
      }

      console.log('Lotto data updated successfully');
      setShowSuccessPopup(true); // แสดง popup ตรวจสำเร็จ
    } catch (error) {
      console.error('Update error:', error);
    }

    // await fetchLottoData();
  };
  const updateUserStatus = async (lottoIds, username) => {
    try {
      const dataToSend = { ids: lottoIds, username };
      // console.log('Data to send:', dataToSend);

      const response = await fetch(`http://autobotlotto.mcpay.in:5678/api/update-userstatus-roundthree`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        throw new Error('Failed to update user status');
      }

      console.log('User status updated successfully');
    } catch (error) {
      console.error('Update error:', error);
    }
  };

  const closePopup = async () => {
    setShowPopup(false);
    await fetchLottoData();
    setTimeoutReached(false);
  };

  const closeSuccessPopup = async () => {
    setShowSuccessPopup(false);
    await fetchLottoData();

    if (productContainerRef.current) {
      productContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const openBackConfirmPopup = () => {
    setShowBackConfirmPopup(true);
  };

  const closeBackConfirmPopup = () => {
    setShowBackConfirmPopup(false);
  };

  const confirmBackAction = async () => {
    if (timeoutId) {
      clearTimeout(timeoutId);  // หยุดการทำงานของ timeout ที่กำหนดไว้
      setTimeoutId(null);        // รีเซ็ตค่า timeoutId เป็น null เพื่อไม่ให้ใช้งานต่อ
    }
    // Handle the action when "ตกลง" is clicked in the confirmation popup
    const lottoIdsToUpdate = currentLottoData.map(item => item.id); // รับ ID ของล็อตโต้ที่จะแก้ไข

    // เรียกใช้ฟังก์ชัน updateUserStatus โดยส่ง username ว่าง
    await updateUserStatus(lottoIdsToUpdate, ''); // ส่งค่าต่างๆ ที่จำเป็นในการอัปเดต
    setTimeoutReached(false);

    setShowBackConfirmPopup(false);
    // navigate(-1);
    navigate('/checklottery');
    // Add logic here if you want to redirect or perform another action on confirmation
  };

  const currentLottoData = lottoData.slice(0, itemsPerPage);

  return (
    <div className="product-container" ref={productContainerRef}>
      <h1 className='lottery-title'>ตรวจล็อตเตอรี่รอบที่ {round}</h1>
      {isLoggedIn ? (
        <>
          {currentLottoData.map((item) => (
            <div className="product-item-wrapper" key={item.id}> {/* เพิ่ม div ครอบ product-item */}
              <div className="product-item">
                <div className="left-column">
                  <img
                    src={item.imageUrl}
                    alt={`Lotto ${item.lottoNumbers.part1}${item.lottoNumbers.part2}${item.lottoNumbers.part3}${item.lottoNumbers.part4}`}
                  />
                  <div className="lotto-number-container">
                    <div className="lotto-numbers">
                      <input
                        className="lotto-input"
                        type="text"
                        value={item.lottoNumbers.part1}
                        onChange={(e) => handleInputChange(item.id, 'part1', e.target.value)}
                        maxLength={2}
                      />
                      <span className="separator">|</span>
                      <input
                        className="lotto-input lotto-input-xxxxxx"
                        type="text"
                        value={item.lottoNumbers.part2}
                        onChange={(e) => handleInputChange(item.id, 'part2', e.target.value)}
                        maxLength={6}
                      />
                      <span className="separator">|</span>
                      <input
                        className="lotto-input"
                        type="text"
                        value={item.lottoNumbers.part3}
                        onChange={(e) => handleInputChange(item.id, 'part3', e.target.value)}
                        maxLength={2}
                      />
                      <span className="separator">|</span>
                      <input
                        className="lotto-input"
                        type="text"
                        value={item.lottoNumbers.part4}
                        onChange={(e) => handleInputChange(item.id, 'part4', e.target.value)}
                        maxLength={2}
                      />
                    </div>
                  </div>
                  <textarea
                    className="remark-input"
                    placeholder="ใส่หมายเหตุที่นี่..."
                    value={item.remark}
                    onChange={(e) => handleRemarkChange(item.id, e.target.value)}
                  />
                </div>
                <div className="right-column">
                  <div className="radio-container">
                    <label>
                      <input
                        type="radio"
                        name={`status-${item.id}`}
                        value="1"
                        checked={item.status === '1'}
                        onChange={() => handleStatusChange(item.id, '1')}
                      />
                      ผ่าน
                    </label>
                    <label>
                      <input
                        type="radio"
                        name={`status-${item.id}`}
                        value="0"
                        checked={item.status === '0'}
                        onChange={() => handleStatusChange(item.id, '0')}
                      />
                      ไม่ผ่าน
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className='note-container-wrapper'>
            <div className="note-container">
              <button className="note-back-button" onClick={openBackConfirmPopup}>Back</button>
              <button className="note-button" onClick={handleButtonClick}>OK</button>
            </div>
          </div>

          <div>
            {showAlert && <AlertModal message="ไม่มี ล็อตเตอรี่" onClose={handleCloseAlert} />}
            {/* ส่วนอื่นๆ ของแอปพลิเคชัน */}
          </div>


          {showPopup && (
            <div className="popup-notification">
              <div className="popup-content">
                <div className="popup-message">
                  คุณทำรายการเกินเวลาที่กำหนด!
                </div>
                <button className="popup-button" onClick={closePopup}>ปิด</button>
              </div>
            </div>
          )}

          {showSuccessPopup && (
            <div className="popup-notification">
              <div className="popup-content">
                <div className="popup-message">
                  ตรวจสำเร็จ!
                </div>
                <button className="popup-button" onClick={closeSuccessPopup}>ปิด</button>
              </div>
            </div>
          )}

          {showBackConfirmPopup && (
            <div className="popup-notification">
              <div className="popup-content">
                <div className="popup-message">
                  คุณแน่ใจหรือไม่ว่าต้องการออกจากหน้านี้!
                </div>
                <div className="popup-buttons">
                  <button className="confirm-button" onClick={confirmBackAction}>ตกลง</button>
                  <button className="cancel-button" onClick={closeBackConfirmPopup}>ยกเลิก</button>

                </div>
              </div>
            </div>
          )}

          {showModal && (
            <div className="modal-overlay">
              <div className="modal-content">
                <h2>คุณต้องการออกจากหน้านี้หรือไม่?</h2>
                <div className="modal-buttons">
                  <button className="btn-confirm" onClick={() => handleCloseModal(true)}>
                    ตกลง
                  </button>
                  <button className="btn-cancel" onClick={() => handleCloseModal(false)}>
                    ยกเลิก
                  </button>
                </div>
              </div>
            </div>
          )}

          {showRefreshModal && (
            <div className="modal-overlay">
              <div className="modal-content">
                <p>คุณต้องการรีเฟรชหน้านี้หรือไม่?</p>
                <button className="btn-confirm" onClick={handleConfirmRefresh}>ยืนยัน</button>
                <button className="btn-cancel" onClick={handleCancelRefresh}>ยกเลิก</button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div>กรุณาเข้าสู่ระบบเพื่อดูข้อมูล</div>
      )}
    </div>
  );
};

export default Roundthree;
