import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainDash from './components/MainDash/MainDash';
import Sidebar from './components/LeftSide/Sidebar';
import Login from './components/Login/Login';
import Checklottery from './components/Checklotto/Checklottery';
import Last2digits from "./components/Last2digits/Last2digits";
import OnestPrize from "./components/OnestPrize/OnestPrize";
import Roundone from "./components/Product/Roundone";
import Roundtwo from "./components/Product/Roundtwo";
import Roundthree from "./components/Product/Roundthree";
import Profile from "./components/Profile/Profile";
import Amindash from "./components/Amindash/Amindash";
import PrivateRoute from './components/PrivateRoute/PrivateRoute'; // นำเข้า PrivateRoute

// Layout component
const Layout = ({ children }) => (
  <>
    <Sidebar />
    {children}
  </>
);

function App() {
  return (
    <div className="App">
      <div className="AppGlass">
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            {/* เส้นทางที่ต้องการให้เฉพาะ admin เข้าถึง */}
            <Route path="/1st-prize" element={<PrivateRoute element={<Layout><OnestPrize /></Layout>} isAdminOnly={true} />} />
            <Route path="/dashboard" element={<PrivateRoute element={<Layout><Amindash /></Layout>} isAdminOnly={true} />} />

            <Route path="/profile" element={<PrivateRoute element={<Layout><Profile /></Layout>} />} />
            <Route path="/checklottery" element={<PrivateRoute element={<Layout><Checklottery /></Layout>} />} />
            <Route path="/Roundone" element={<PrivateRoute element={<Roundone />} />} />
            <Route path="/Roundtwo" element={<PrivateRoute element={<Roundtwo />} />} />
            <Route path="/Roundthree" element={<PrivateRoute element={<Roundthree />} />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}


export default App;
