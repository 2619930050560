import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './OnestPrize.css'; // ปรับตามชื่อไฟล์ CSS ของคุณ
import Notification from '../Checklotto/Notification';

const OnestPrize = () => {
  const [positionStatistics, setPositionStatistics] = useState([]);
  const [newLottoNumbers, setNewLottoNumbers] = useState([]); // สถานะสำหรับเลขใหม่
  const [currentPage, setCurrentPage] = useState(1); // สถานะสำหรับหน้า
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState({}); // สถานะสำหรับเลขที่ถูกเลือก
  const [displayedNumbers, setDisplayedNumbers] = useState([]); // สำหรับแสดงเลขที่สร้าง
  const [loadingGenerate, setLoadingGenerate] = useState(false); // เพิ่ม loading สำหรับการสร้างเลข
  const [generatedCount, setGeneratedCount] = useState(0); // สถานะสำหรับเก็บจำนวนเลขที่สร้าง
  const [isBuilding, setIsBuilding] = useState(true); // สถานะของปุ่ม
  const [selectedDate, setSelectedDate] = useState(new Date()); // สถานะสำหรับเก็บวันที่ที่เลือก
  const [statusMessage, setStatusMessage] = useState('');
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const itemsPerPage = 50; // จำนวน item ที่แสดงต่อหน้า

  const fetchStatus = async () => {
    try {
      const response = await axios.get('http://autobotlotto.mcpay.in:5678/api/botstatus');
      setStatusMessage(response.data.message); // เก็บข้อมูลที่ได้รับในตัวแปรสถานะ
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching data:', error); // แสดงข้อผิดพลาด
    }
  };

  // เรียก fetchStatus หนึ่งครั้งเมื่อ component โหลด
  useEffect(() => {
    fetchStatus();
  }, []);

  // ใช้ useEffect เพื่อตรวจสอบการเปลี่ยนแปลงของ statusMessage
  useEffect(() => {
    if (statusMessage) {
      console.log("Status message has changed:", statusMessage);
    }
  }, [statusMessage]); // รันทุกครั้งที่ statusMessage เปลี่ยนแปลง




  useEffect(() => {
    // กำหนดวันที่ดีฟอลต์
    const today = new Date();
    const currentDay = today.getDate();

    let defaultDate;
    if (currentDay > 1 && currentDay <= 16) {
      // ถ้าวันนี้มากกว่า 1 แต่ไม่เกิน 15 ให้แสดงวันที่ 16
      defaultDate = new Date(today.getFullYear(), today.getMonth(), 16);
    } else if (currentDay > 16) {
      // ถ้าวันนี้มากกว่า 16 ให้แสดงวันที่ 1 ของเดือนถัดไป
      defaultDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    } else {
      // ถ้าวันนี้คือวันที่ 1 หรือก่อนหน้านั้นให้แสดงวันที่ 1 ของเดือนนี้
      defaultDate = new Date(today.getFullYear(), today.getMonth(), 1);
    }

    setSelectedDate(defaultDate);
  }, []);


  useEffect(() => {
    const fetchPositionStatistics = async () => {
      try {
        const response = await axios.get('http://autobotlotto.mcpay.in:5678/api/lottosixnumber');
        setPositionStatistics(response.data.positionStatistics);
        // setNewLottoNumbers(response.data.newLottoNumbers); // ดึง newLottoNumbers จาก API
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('เกิดข้อผิดพลาดในการดึงข้อมูล');
      } finally {
        setLoading(false);
      }
    };

    fetchPositionStatistics();
  }, []);



  // คำนวณเลขเริ่มต้นและเลขสิ้นสุดสำหรับหน้าปัจจุบัน
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNumbers = newLottoNumbers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(displayedNumbers.length / itemsPerPage);

  const handleCheckboxChange = (digit, position) => {
    // console.log(`ตำแหน่งที่ ${position} - เลข: ${digit} checkbox changed`);

    setSelected((prev) => {
      const updatedSelection = {
        ...prev,
        [position]: {
          ...prev[position],
          [digit]: !prev[position]?.[digit], // สลับสถานะของ checkbox
        },
      };

      // console.log('ค่าที่เลือกในปัจจุบัน:', updatedSelection);

      const selectedNumbers = {};
      for (let pos = 1; pos <= 6; pos++) {
        selectedNumbers[pos] = Object.keys(updatedSelection[pos] || {})
          .filter((key) => updatedSelection[pos][key])
          .map((key) => key);
      }

      const newPairedNumbers = [];
      const sortedDigits = [...positionStatistics[position - 1].digits]
        .sort((a, b) => b.count - a.count);

      const combinations = (current, depth) => {
        if (depth === 6) {
          newPairedNumbers.push(current.join(''));
          return;
        }

        for (const digitData of sortedDigits) {
          const digit = digitData.digit;
          if (selectedNumbers[depth + 1]?.includes(digit)) {
            combinations([...current, digit], depth + 1);
          }
        }
      };

      combinations([], 0);

      // console.log('เลขที่จับคู่ใหม่:', newPairedNumbers);

      // อัปเดตจำนวนเลขที่ถูกสร้าง
      setGeneratedCount(newPairedNumbers.length);

      return updatedSelection;
    });
  };


  const handleGenerateNumbers = () => {
    setLoadingGenerate(true); // เริ่มต้น loading
    setIsBuilding(false); // เปลี่ยนสถานะปุ่มเป็น Submit

    const newPairedNumbers = []; // เก็บเลขที่สร้างใหม่
    const sortedDigits = [];
    const selectedNumbers = {};

    for (let pos = 1; pos <= 6; pos++) {
      selectedNumbers[pos] = Object.keys(selected[pos] || {})
        .filter((key) => selected[pos][key])
        .map((key) => key);
      sortedDigits.push(...positionStatistics[pos - 1].digits);
    }

    const combinations = (current, depth) => {
      if (depth === 6) {
        newPairedNumbers.push(current.join('')); // สร้างเลข 6 ตัว
        return;
      }

      for (const digitData of sortedDigits) {
        const digit = digitData.digit;
        if (selectedNumbers[depth + 1]?.includes(digit)) {
          combinations([...current, digit], depth + 1); // เรียกฟังก์ชันซ้ำกับเลขที่เลือก
        }
      }
    };

    // เรียกใช้ combinations
    combinations([], 0);

    // อัปเดตเลขที่สร้างใหม่ใน state โดยไม่เก็บเลขเก่า
    setDisplayedNumbers([...new Set(newPairedNumbers)]);

    // รีเซ็ตหน้าเมื่อสร้างเลขใหม่
    setCurrentPage(1); // รีเซ็ตไปที่หน้าแรก

    // ใช้ setTimeout เพื่อให้เห็น spinner สักครู่ก่อนที่จะเสร็จสิ้น
    setTimeout(() => {
      setLoadingGenerate(false); // เสร็จสิ้น loading
    }, 1000); // สามารถปรับเวลาได้ตามต้องการ
  };


  const handleSubmit = async () => {
    // ตรวจสอบสถานะว่าเป็น 'Bot disconnected'
    if (statusMessage === 'Bot disconnected') {
      // แสดงข้อความแจ้งเตือนเมื่อไม่ได้เชื่อมต่อ
      setNotificationMessage('ไม่สามารถส่งข้อมูลได้ เนื่องจากการเชื่อมต่อถูกตัด');
      setIsNotificationOpen(true);
      setIsBuilding(true);
      return; // หยุดการทำงานของฟังก์ชัน
    }

    try {
      // สร้างวันที่ใหม่จาก selectedDate
      const date = new Date(selectedDate);

      // เพิ่มวันเข้าไป 1 วัน
      date.setDate(date.getDate() + 1);

      // แปลงวันที่เป็น ISO string ในรูปแบบ YYYY-MM-DD
      const dateFormatted = date.toISOString().split('T')[0];

      console.log('Sending data:', { numbers: displayedNumbers });
      const response = await fetch('http://autobotlotto.mcpay.in:5678/api/saveLottoNumbers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          numbers: displayedNumbers,
          date: dateFormatted, // ส่งวันที่ไปใน request
        }),
      });

      console.log('HTTP Status:', response.status); // แสดงสถานะ HTTP

      if (!response.ok) {
        const errorMessage = await response.text(); // อ่านข้อความผิดพลาดจากการตอบกลับ
        throw new Error(`Network response was not ok: ${errorMessage}`);
      }

      const data = await response.json();
      console.log('Response:', data);

      // ตั้งค่าข้อความแจ้งเตือนเมื่อส่งข้อมูลสำเร็จ
      setNotificationMessage('ส่งข้อมูลสำเร็จ!');
      setIsNotificationOpen(true);
      setIsBuilding(true); // แสดงสถานะกำลังประมวลผล
    } catch (error) {
      console.error('Error submitting data:', error);

      // ตั้งค่าข้อความแจ้งเตือนเมื่อมีข้อผิดพลาด
      setNotificationMessage(`ส่งข้อมูลไม่สำเร็จ!: ${error.message}`);
      setIsNotificationOpen(true);
    }
  };


  const handleCloseNotification = () => {
    setIsNotificationOpen(false);
  };


  // ฟังก์ชันสำหรับเปลี่ยนหน้า
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // คำนวณเลขที่จะแสดงในหน้าปัจจุบัน
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = displayedNumbers.slice(startIndex, endIndex);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="scrollable-container">
      <h1>รางวัลที่ 1</h1>
      <div className="position-grid">
        {positionStatistics.map((positionData) => (
          <div key={positionData.position} className="position-item">
            <h3>ตำแหน่งที่ {positionData.position}</h3>
            <ul>
              {positionData.digits.map((digitData) => (
                <li key={digitData.digit}>
                  เลข: {digitData.digit} จำนวน: {digitData.count} ครั้ง
                  <input
                    type="checkbox"
                    className="digit-checkbox"
                    onChange={() => handleCheckboxChange(digitData.digit, positionData.position)} // ฟังก์ชันสำหรับจัดการการเปลี่ยนแปลงเช็กบล็อก
                  />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>


      <div className="quantity-container">
        <div className="quantity-label">
          จำนวนเลข : {generatedCount} ใบ
        </div>
        <div>
          <p className={statusMessage === 'Bot disconnected' ? 'disconnected' : statusMessage === 'Bot connected' ? 'connected' : ''}>
            {statusMessage || "Bot disconnected"}
          </p>
        </div>
        
        <div className="date-picker">
          <label>งวดวันที่:</label>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="dd/MM/yyyy"
            placeholderText="เลือกวันที่"
          />
        </div>
        <button className={isBuilding ? "build-button" : "submit-button"} onClick={isBuilding ? handleGenerateNumbers : handleSubmit}>
          {isBuilding ? 'Create' : 'Send data'}
        </button>
      </div>

      {isNotificationOpen && (
        <Notification message={notificationMessage} onClose={handleCloseNotification} />
      )}

      <div className="CardonestPrize">
        <h3>New Number</h3>
        {loadingGenerate ? ( // แสดง loading spinner หากกำลังสร้างเลข
          <div className="loading-container">
            <div className="loading-spinner"></div> {/* เรียกใช้ CSS Spinner */}
            <div className="loading-text">กำลังสร้าง...</div>
          </div>
        ) : (
          <div className="new-numbers-grid">
            {currentItems.length > 0 ? (
              currentItems.map((number, index) => (
                <div key={index} className="new-number-item">
                  {number}
                </div>
              ))
            ) : (
              <div className="no-numbers">ยังไม่มีเลขที่สร้าง</div>
            )}
          </div>
        )}

        <div className="pagination-controls">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            ก่อนหน้า
          </button>
          <span>หน้า {currentPage} จาก {totalPages}</span>
          <button onClick={handleNextPage} disabled={currentPage >= totalPages}>
            ถัดไป
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnestPrize;
