import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // นำเข้า useNavigate
import "./Sidebar.css";
import { UilBars } from "@iconscout/react-unicons";
import { SidebarData } from "../../Data/Data";
import Image from "../../imgs/numchok.png"; // ภาพที่ใช้ในหน้า
import { motion } from "framer-motion";

const Sidebar = () => {
  const [selected, setSelected] = useState(0);
  const [expanded, setExpaned] = useState(true);
  const navigate = useNavigate(); // สร้าง instance ของ navigate

  const handleLogoClick = () => {
    navigate('/Profile');
  };

  const sidebarVariants = {
    true: {
      left: '0',
    },
    false: {
      left: '-60%',
    },
  };

  const handleLogout = () => {
    // ลบข้อมูลผู้ใช้จาก Local Storage
    localStorage.removeItem('status');
    localStorage.removeItem('username');
    // นำทางไปยังหน้า Login
    navigate('/login');
  };

  // เช็คว่าผู้ใช้เป็น 'amin' หรือไม่
  const status = localStorage.getItem('status'); // รับชื่อผู้ใช้จาก Local Storage
  const isAdmin = status === 'Admin'; // เช็คว่าผู้ใช้คือ 'amin' หรือไม่

  return (
    <>
      <div className="bars" style={expanded ? { left: '60%' } : { left: '5%' }} onClick={() => setExpaned(!expanded)}>
        <UilBars />
      </div>
      <motion.div className='sidebar'
        variants={sidebarVariants}
        animate={window.innerWidth <= 768 ? `${expanded}` : ''}
      >
        <div className="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
          <img src={Image} alt="Logo" /> Numchoke
        </div>

        <div className="menu">
          {SidebarData.map((item, index) => {
            // แสดงเฉพาะไอเทมที่ 1, 5, 6, 7 ถ้าไม่ใช่ 'amin'
            if (!isAdmin && ![0, 3, 4, 5, 6].includes(index)) {
              return null; // ไม่แสดงไอเทมอื่น
            }

            return (
              <div
                className={selected === index ? "menuItem active" : "menuItem"}
                key={index}
                onClick={() => {
                  if (item.heading === "Logout") {
                    handleLogout(); // เรียกฟังก์ชัน logout
                  } else {
                    setSelected(index);
                    navigate(`/${item.heading.toLowerCase()}`); // ใช้ navigate เพื่อเปลี่ยนหน้า
                  }
                }}
              >
                <item.icon />
                <span>{item.heading}</span>
              </div>
            );
          })}
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
