import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Notification from './Notification'; // นำเข้าคอมโพเนนต์การแจ้งเตือน
import './Checklottery.css';

const Checklottery = () => {
  const navigate = useNavigate();
  const [lotteryData, setLotteryData] = useState([]);
  const [roundTwoData, setRoundTwoData] = useState([]);
  const [roundThreeData, setRoundThreeData] = useState([]);
  const [uncheckedLotto, setUncheckedLotto] = useState(0);
  const [checkedLotto, setCheckedLotto] = useState(0);
  const [uncheckedRoundTwo, setUncheckedRoundTwo] = useState(0);
  const [checkedRoundTwo, setCheckedRoundTwo] = useState(0);
  const [uncheckedRoundThree, setUncheckedRoundThree] = useState(0);
  const [checkedRoundThree, setCheckedRoundThree] = useState(0);
  // สถานะเก็บข้อมูล Round_one ของแต่ละรายการ
  const [roundOneList, setRoundOneList] = useState([]);
  const [roundtwoList, setRoundtwoList] = useState([]);

  const [totalRoundOne, setTotalRoundOne] = useState(0);
  const [totalRoundTwo, setTotalRoundTwo] = useState(0);

  const loggedInUser = localStorage.getItem('username'); // ดึงค่าจาก localStorage

  // สถานะสำหรับการแจ้งเตือน
  const [notification, setNotification] = useState({
    message: '',
    show: false,
  });

  const fetchLotteryData = async () => {
    try {
      const response1 = await axios.get('http://autobotlotto.mcpay.in:5678/api/lotto-numbers');
      setLotteryData(response1.data);

      const uncheckedCount = response1.data.filter(lotto => !lotto.userstatus).length;
      const checkedCount = response1.data.filter(lotto => lotto.userstatus).length;

      setUncheckedLotto(uncheckedCount);
      setCheckedLotto(checkedCount);

      const response2 = await axios.get('http://autobotlotto.mcpay.in:5678/api/lotto-numbers-roundtwo');
      setRoundTwoData(response2.data);

      // เก็บข้อมูล Round_one ของแต่ละรายการ
      const roundOneListData = response2.data.map((item) => item.Round_one);
      // console.log("roundOneListData data",roundOneListData)
      setRoundOneList(roundOneListData);

      const totalRound_one = response2.data.filter(item => item.Round_one).length;
      setTotalRoundOne(totalRound_one);

      const uncheckedCountRoundTwo = response2.data.filter(lotto => !lotto.Round_two).length;
      const checkedCountRoundTwo = response2.data.filter(lotto => lotto.Round_two).length;

      setUncheckedRoundTwo(uncheckedCountRoundTwo);
      setCheckedRoundTwo(checkedCountRoundTwo);

      const response3 = await axios.get('http://autobotlotto.mcpay.in:5678/api/lotto-numbers-roundthree');
      setRoundThreeData(response3.data);

      const roundtwoListData = response2.data.map((item) => item.Round_two);
      console.log("roundtwoListData data",roundtwoListData)
      setRoundtwoList(roundtwoListData);

      const totalRound_two = response3.data.filter(item => item.Round_two).length;
      setTotalRoundTwo(totalRound_two);

      const uncheckedCountRoundThree = response3.data.filter(lotto => !lotto.Round_three).length;
      const checkedCountRoundThree = response3.data.filter(lotto => lotto.Round_three).length;

      setUncheckedRoundThree(uncheckedCountRoundThree);
      setCheckedRoundThree(checkedCountRoundThree);



    } catch (error) {
      console.error('Error fetching lottery data:', error);
    }
  };

  useEffect(() => {
    fetchLotteryData();
  }, []);

  const handleCheckLottery = (round) => {
    // ตรวจสอบว่าล็อตเตอรี่ทั้งหมดทั้ง 3 รอบเท่ากับ 0 หรือไม่
    if (
      lotteryData.length === 0 &&
      roundTwoData.length === 0 &&
      roundThreeData.length === 0
    ) {
      setNotification({ message: 'ยังไม่มีล็อตเตอรี่', show: true });
      return;
    }
    if (round === 1) {
      // ตรวจสอบสำหรับรอบที่ 1
      if (lotteryData.length > 0 && checkedLotto === lotteryData.length) {
        setNotification({ message: 'ล็อตเตอรี่หมด', show: true });
        return;
      }
      navigate('/Roundone', { state: { round } });

    } else if (round === 2) {
      
      // ตรวจสอบว่า user ที่ล็อกอินมีอยู่ใน `roundOneList` หรือไม่
      const userExistsInRoundOne = roundOneList.some(item => item === loggedInUser);

      if (!userExistsInRoundOne) {
        setNotification({ message: 'คุณไม่ได้เข้าร่วมรอบที่ 1', show: true });
        return;
      }
      // ตรวจสอบสำหรับรอบที่ 2
      if (totalRoundOne > 0 && checkedRoundTwo === totalRoundOne) {
        setNotification({ message: 'ล็อตเตอรี่หมด', show: true });
        return;
      }
      // if (checkedLotto !== lotteryData.length) {
      //   setNotification({ message: 'กรุณาตรวจรอบที่ 1 ให้ครบ', show: true });
      //   return;
      // }
      navigate('/Roundtwo', { state: { round } });

    } else if (round === 3) {

      const userExistsInRoundOne = roundOneList.some(item => item === loggedInUser);
      const userExistsInRoundtwo = roundtwoList.some(item => item === loggedInUser);

      if (!userExistsInRoundOne) {
        setNotification({ message: 'คุณไม่ได้เข้าร่วมรอบที่ 1', show: true });
        return;
      }else if(!userExistsInRoundtwo){
        setNotification({ message: 'คุณไม่ได้เข้าร่วมรอบที่ 2', show: true });
        return;
      }
      // ตรวจสอบสำหรับรอบที่ 3
      if (totalRoundTwo > 0 && checkedRoundThree === totalRoundTwo) {
        setNotification({ message: 'ล็อตเตอรี่หมด', show: true });
        return;
      }
      if (totalRoundOne === 0) {
        setNotification({ message: 'ยังไม่มีล็อตเตอรี่ในรอบที่ 2', show: true });
        return;
      }
      // if (checkedRoundTwo !== totalRoundOne) {
      //   setNotification({ message: 'กรุณาตรวจรอบที่ 2 ให้ครบ', show: true });
      //   return;
      // }
      navigate('/Roundthree', { state: { round } });
    } else {
      console.log('Invalid round');
    }
  };


  const handleCloseNotification = () => {
    setNotification({ ...notification, show: false });
  };

  return (
    <div className="lottery-container">
      <h1 className="lottery-title">ตรวจล็อตเตอรี่</h1>
      <div className="lottery-grid">
        {/* รอบที่ 1 */}
        <div className="lottery-section">
          <h2 className="lottery-subtitle">รอบที่ 1</h2>
          <div className="lottery-round">
            <div className="lottery-info">ล็อตเตอรี่ทั้งหมด <span>{lotteryData.length}</span> ใบ</div>
            <div className="lottery-info">ยังไม่ได้ตรวจ <span>{uncheckedLotto}</span> ใบ</div>
            <div className="lottery-info">ตรวจแล้ว <span>{checkedLotto}</span> ใบ</div>
            <button className="lottery-btn" onClick={() => handleCheckLottery(1)}>ขอตรวจ</button>
          </div>
        </div>

        {/* รอบที่ 2 */}
        <div className="lottery-section">
          <h2 className="lottery-subtitle">รอบที่ 2</h2>
          <div className="lottery-round">
            <div className="lottery-info">ล็อตเตอรี่ทั้งหมด <span>{totalRoundOne}</span> ใบ</div>
            <div className="lottery-info">ยังไม่ได้ตรวจ <span>{uncheckedRoundTwo}</span> ใบ</div>
            <div className="lottery-info">ตรวจแล้ว <span>{checkedRoundTwo}</span> ใบ</div>
            <button className="lottery-btn" onClick={() => handleCheckLottery(2)}>ขอตรวจ</button>
          </div>
        </div>

        {/* รอบที่ 3 */}
        <div className="lottery-section">
          <h2 className="lottery-subtitle">รอบที่ 3</h2>
          <div className="lottery-round">
            <div className="lottery-info">ล็อตเตอรี่ทั้งหมด <span>{totalRoundTwo}</span> ใบ</div>
            <div className="lottery-info">ยังไม่ได้ตรวจ <span>{uncheckedRoundThree}</span> ใบ</div>
            <div className="lottery-info">ตรวจแล้ว <span>{checkedRoundThree}</span> ใบ</div>
            <button className="lottery-btn" onClick={() => handleCheckLottery(3)}>ขอตรวจ</button>
          </div>
        </div>
      </div>

      {/* แสดงการแจ้งเตือน */}
      {notification.show && (
        <Notification message={notification.message} onClose={handleCloseNotification} />
      )}
    </div>
  );
};

export default Checklottery;
